#event-descriptor > .content > ul {
  text-align: left;
  margin-top: 0;
  list-style: none;
  animation-name: change;
  animation-duration: 20s;
  animation-iteration-count: infinite;
}

@keyframes opacity {
  0%, 100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@keyframes change {
  0%, 7.5%, 100% {
    transform: translate3d(0, 0, 0);
  }

  8.33%, 15.83% {
    transform: translate3d(0, -14.29%, 0);
  }

  16.67%, 24.17% {
    transform: translate3d(0, -28.57%, 0);
  }

  25%, 32.5% {
    transform: translate3d(0, -42.86%, 0);
  }

  33.33%, 40.83% {
    transform: translate3d(0, -57.14%, 0);
  }

  41.67%, 49.17% {
    transform: translate3d(0, -71.43%, 0);
  }

  50%, 57.5% {
    transform: translate3d(0, -85.71%, 0);
  }

  58.33%, 65.83% {
    transform: translate3d(0, -71.43%, 0);
  }

  66.67%, 74.17% {
    transform: translate3d(0, -57.14%, 0);
  }

  75%, 82.5% {
    transform: translate3d(0, -42.86%, 0);
  }

  83.33%, 90.83% {
    transform: translate3d(0, -28.57%, 0);
  }

  91.67%, 99.17% {
    transform: translate3d(0, -14.29%, 0);
  }
}
/*# sourceMappingURL=index.3b1c9851.css.map */
